function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function isTokensAreInLocalStorage() {

  var access_token = localStorage.getItem('access_token');
  var refresh_token = localStorage.getItem('refresh_token');
  var token_expires_in = localStorage.getItem('token_expires_in');
  
  console.log("access_token = " + access_token);
  console.log("refresh_token = " + refresh_token);
  console.log("token_expires_in = " + token_expires_in);

  // Token exist..
  if(! access_token ){
    // Check Cookies...
    access_token = getCookie('access_token');
    if(! access_token ){
      return false;
    }
    // Store Tokens in Local Storage.
    localStorage.setItem('access_token', access_token);
  }

  // Validate Tokens.... Expiraton date... Roles ....

  return true;  
}

