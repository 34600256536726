import { registerApplication, start } from "single-spa";
//import * as singleSpa from 'single-spa';
import { isTokensAreInLocalStorage } from "./module";


if( ! isTokensAreInLocalStorage() ) {

    // Redirect to login page. Need to know if better to use href or replace
    //location.href = "https://devpm.fr/TestOkta/Login-Page";
    location.replace("https://dev-5068036.okta.com/home/oidc_client/0oa247nh5spRcTxni5d6/aln177a159h7Zf52X0g8");
    //location.replace('https://devpm.fr/Alpega-Login-Page/');
}
else {

  registerApplication({
    name: "@devpm/demo-nav-auth",
    app: () => System.import("@devpm/demo-nav-auth"),
    activeWhen: [(location) => { return location.pathname === "/"; }]
  });

  registerApplication({
    name: "@devpm/demo-page-un",
    app: () => System.import("@devpm/demo-page-un"),
    activeWhen: [(location) => { return location.hash === "#/page1"; }]
  });

  registerApplication({
    name: "@devpm/demo-page-deux",
    app: () => System.import("@devpm/demo-page-deux"),
    activeWhen: [(location) => { return location.hash === "#/page2"; }]
  });

  registerApplication({
    name: "@devpm/demo-page-trois",
    app: () => System.import("@devpm/demo-page-trois"),
    activeWhen: [(location) => { return location.hash === "#/page3"; }]
  });


  start({
    urlRerouteOnly: true,
  });

}